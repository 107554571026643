import cloneDeep from 'lodash/cloneDeep';
import Plotly from 'plotly.js-strict-dist';
import events from './events';
import methods from './methods';

/**
 * Mostly based on https://www.somesolvedproblems.com/2019/02/tutorial-writing-vue-app-from-start-to.html
 *
 */
export default {
  name: 'PlotlyGraph',
  inheritAttrs: false,

  props: {
    data: {
      type: Array,
    },
    layout: {
      type: Object,
    },
    config: {
      type: Object,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      scheduled: null,
      innerLayout: cloneDeep(this.layout),
    };
  },

  computed: {
    innerConfig() {
      return {
        responsive: false,
        ...this.config,
      };
    },
  },

  methods: {
    ...methods,

    onResize() {
      Plotly.Plots.resize(this.$el);
      this.schedule({ replot: false });
    },

    schedule(context) {
      const { scheduled } = this;
      if (scheduled) {
        scheduled.replot = scheduled.replot || context.replot;
        return;
      }

      this.scheduled = context;

      this.$nextTick(() => {
        const {
          scheduled: { replot },
        } = this;
        this.scheduled = null;
        if (replot) {
          this.react();
          return;
        }
        this.update(this.innerLayout);
      });
    },

    toImage(options) {
      const allOptions = Object.assign(this.getPrintOptions(), options);
      return Plotly.toImage(this.$el, allOptions);
    },

    downloadImage(options) {
      const filename = `plot--${new Date().toISOString()}`;
      const allOptions = Object.assign(this.getPrintOptions(), { filename }, options);
      return Plotly.downloadImage(this.$el, allOptions);
    },

    getPrintOptions() {
      const { $el } = this;
      return {
        format: 'png',
        width: $el.clientWidth,
        height: $el.clientHeight,
      };
    },

    react() {
      Plotly.react(this.$el, this.data, this.innerLayout, this.innerConfig);
    },

    /**
     * @see https://plotly.com/javascript/plotlyjs-function-reference/#plotlymovetraces
     * @param which - trace index or array containing set of indexes which will be moved, negative values are allowed
     * @param where - default end of traces, defines destination of traces, numeric value (index) or array containing indexes
     */
    moveTraces(which, where) {
      Plotly.moveTraces(this.$el, which, where);
    },
  },

  mounted() {
    Plotly.newPlot(this.$el, this.data, this.innerLayout, this.innerConfig);

    // proxy for events
    events.forEach((evt) => {
      this.$el.on(evt.completeName, evt.handler(this));
    });
  },

  beforeDestroy() {
    events.forEach((event) => this.$el.removeAllListeners(event.completeName));
    Plotly.purge(this.$el);
  },

  watch: {
    data: {
      handler() {
        this.$log.debug('PG data handler');
        this.schedule({ replot: true });
      },
      deep: true,
    },
    innerConfig: {
      handler(value, old) {
        this.$log.debug('PG config handler');
        if (JSON.stringify(value) === JSON.stringify(old)) {
          return;
        }
        this.schedule({ replot: true });
      },
      deep: true,
    },
    layout(layout) {
      this.$log.debug('PG layout handler');
      this.innerLayout = cloneDeep(layout);
      this.schedule({ replot: false });
    },
  },
};
